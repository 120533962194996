.menu {
  background: #152238;
  padding: 20px 25px 30px;
  margin: 50px auto;
  width: 75%;
}

.grid {
  margin: 10px 0px 20px;
}

@media only screen and (min-width: 900px) {
  .menu {
    width: 65%;
  }
}

@media only screen and (min-width: 1200px) {
  .menu {
    width: 55%;
  }
}

@media only screen and (min-width: 1600px) {
  .menu {
    width: 45%;
  }
}
