.grid1 {
  margin: 10px 0px 30px;
}

.grid2 {
  margin: 0px 0px 10px;
}

.grid3 {
  margin: 50px 0px 0px;
}

.grid4 {
  margin: 30px 0px 30px;
}

@media only screen and (max-width: 499px) {
  .gridScore {
    margin: 50px 0px;
  }
}

@media only screen and (min-width: 900px) {
  .menu {
    width: 55%;
  }
}

@media only screen and (min-width: 1200px) {
  .menu {
    width: 45%;
  }
}

@media only screen and (min-width: 1600px) {
  .menu {
    width: 35%;
  }
}
